@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000c25;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
}

a {
  color: #fff;
  text-decoration: none;
}

/********************************************************* HEADER *****************************************************************/

.header-ctn {
  width: 100%;
  padding: 25px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.header-ctn h1 {
  display: inline-block;
  position: relative;
  font-size: 23px;
  margin: 0;
  z-index: 99;
}

.blip {
  height: 25px;
  position: absolute;
  left: 80px;
  top: 40px;
}

.nav-ctn a,
.navlink {
  font-size: 16px;
  margin-left: 40px;
  display: inline-block;
  cursor: pointer;
}

.nav-ctn a:first-child:hover,
.navlink:hover {
  color: #0074c2;
}

/********************************************************* GLOW BUTTON *****************************************************************/

.glow-on-hover {
  border: none;
  outline: none;
  color: #fff !important;
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-size: 16px;
  padding: 5px 16px;
  font-family: 'Poppins';
  user-select: none;
  border-radius: 2px;
  transition: all 200ms ease-in-out;
}

.glow-on-hover:hover {
  transform: translateY(-2px);
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #0074c2, #ff21d0);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 3px;
}

.glow-on-hover:hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #ff21d0);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/************************************************ Landing Page *****************************************************************/

:root {
  --row-height: 40px;
  --cell-spacing: 2px;
  --row-background: #1a253b;
  --check-col-width: 60px;
}

.loading-ctn {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-top: 100px;
}

.loading-ctn-inner {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-ctn {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px 50px 50px;
}

.padding-right {
  padding-right: 100px;
  padding-left: 100px;
}

.list-ctn-inner {
  width: 100%;
  max-width: 1000px;
}

.row-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--cell-spacing);
  transition: all 200ms ease-in-out;
}

.row-ctn:hover .logo-col,
.row-ctn:hover .rank-col {
  background-color: #273452;
}

.rank-col {
  height: var(--row-height);
  width: var(--row-height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--cell-spacing);
  background: var(--row-background);
  font-size: 14px;
  position: relative;
}

.logo-col {
  height: var(--row-height);
  display: flex;
  align-items: center;
  margin-right: var(--cell-spacing);
  flex-grow: 2;
  padding-left: 12px;
  background: var(--row-background);
}

.logo-col img {
  height: 22px;
}

.check-col {
  height: var(--row-height);
  width: var(--check-col-width);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--cell-spacing);
  background: var(--row-background);
  background: rgba(255, 255, 255, 0.15);
  font-weight: 700;
}

.check-col img {
  height: 12px;
}

.check-col:last-child {
  margin-right: 0;
}

.decimal-col {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  /* overflow-wrap: anywhere;
  text-align: center; */
}

.fee-col {
  font-size: 14px;
}

.decimal-col span,
.fee-questionmark,
.check-col {
  font-size: 16px;
}

.details-ctn {
  width: 100%;
  margin-top: 40px;
  background-color: var(--row-background);
  display: flex;
  flex-direction: column;
  padding: 40px;
  clip-path: polygon(100% 0, 100% 90%, 95% 100%, 0 100%, 0 0);
  box-sizing: border-box;
}

.details-ctn h2 {
  font-size: 26px;
  margin: 0;
}

.exchange-detail-img {
  display: flex;
  align-items: center;
}

.exchange-detail-img img {
  height: 50px;
}

.checkcircle,
.xcircle {
  width: 10px;
  height: 10px;
  padding: 4px;
  background-color: #0074c2;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkcircle img {
  width: 100%;
}

.xcircle {
  background-color: #a10b0b;
}

.xcircle img {
  width: 80%;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  background-color: #0074c2;
}

.detail-row-checkbox {
  height: 40px;
  width: 30px;
  /* border-right: 1px solid var(--row-background);
  margin-right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex: none;
}

.detail-row-checkbox img {
  height: 10px;
}

.detail-row-divider {
  /* height: 10px; */
  width: 1px;
  background-color: var(--row-background);
  margin-right: 10px;
  align-self: stretch;
}

.red {
  background-color: #af0707;
}

.gray {
  background-color: #303b4e;
}

.black {
  background-color: #0d1014;
}

.error-row {
  background-color: #b95e5e;
  font-size: 16px;
  padding: 20px 40px;
  margin-bottom: 1px;
}

.error-row-undefined {
  background-color: #303b4e;
  font-size: 16px;
  padding: 20px 40px;
  margin-bottom: 1px;
}

.error-row a {
  color: #003152;
  text-decoration: underline;
}

.error-row a:hover {
  color: #000c14;
  text-decoration: underline;
}

.detail-row h3 {
  margin: 0;
  font-size: 16px;
}

.visit-link {
  margin-top: 40px;
  padding: 8px 30px;
  background-color: #0074c2;
  font-size: 16px;
  color: #fff;
  display: inline-block;
  align-self: flex-start;
  transition: all ease-in-out 100ms;
}

.visit-link:hover {
  background-color: #0085dd;
}

.back-btn {
  margin-top: 20px;
  padding: 4px 20px;
  background-color: var(--row-background);
  font-size: 16px;
  color: #fff;
  display: inline-block;
  align-self: flex-end;
  transition: all ease-in-out 100ms;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #354564;
}

.logo-score-ctn {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.score-ctn {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-top: 40px;
}

.scorebox {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: var(--row-background);
  font-size: 18px;
  border-radius: 200px;
  font-weight: 600;
  border: 1px solid rgb(78, 82, 94);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(78, 82, 94);
  margin: 30px 0;
}

.fix-title {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

.fix-p {
  font-size: 16px;
}

.fix-p a {
  color: #0074c2;
}

.fix-p a:hover {
  color: #007fd4;
  text-decoration: underline;
}

/************************************************ HEADERS *****************************************************************/

.list-header-ctn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0px;
  background-color: rgba(0, 12, 37, 0.9);
  height: 160px;
  align-items: flex-end;
  z-index: 99999;
}

.list-header-ctn h2 {
  flex-grow: 2;
  margin: 0 0 15px 0;
  font-size: 18px;
}

.list-header-ctn-inner {
  display: flex;
  width: calc((var(--check-col-width) * 7) + 12px);
  position: relative;
}

.list-header-ctn-inner-APPS {
  display: flex;
  width: calc((var(--check-col-width) * 3) + 6px);
  position: relative;
}

.list-header-ctn-inner-INSTANT {
  display: flex;
  width: calc((var(--check-col-width) * 5) + 6px);
  position: relative;
}

.list-label-ctn {
  width: var(--check-col-width);
  margin-left: 2px;
}

.list-label {
  transform: rotate(-30deg);
  white-space: nowrap;
  width: var(--check-col-width);
  margin-left: calc(var(--check-col-width) / 2 + 28px);
  margin-bottom: 26px;
  font-size: 14px;
}

.list-label-background {
  position: absolute;
  background-color: var(--row-background);
  width: calc(var(--check-col-width) - 1px);
  height: 145px;
  bottom: 2px;
  transform: skewX(-60deg);
  margin-left: 126px;
  /* background: linear-gradient(0deg, rgba(26,37,59,1) 0%, rgba(0, 12, 37,1) 100%); */
}

.list-label-ctn:hover .list-label-background {
  background-color: #0074c2;
}

.bottom-text-ctn {
  text-align: center;
}

.bottom-text-ctn p {
  margin-bottom: 40px;
}

.bottom-text-ctn p a,
.modal-link {
  font-weight: 700;
  text-decoration: underline;
  color: #fff;
}

.bottom-text-ctn p a:hover,
.modal-link:hover {
  text-decoration: underline;
  color: #0074c2;
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

/*********************************** MODAL *****************************************/

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 13, 41, 0.8);
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
}

.modal-border {
  width: 80%;
  max-width: 700px;
  z-index: 9999;
  background-color: #273498;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  padding: 2px;
  text-align: center;
  line-height: 1.9em;
  font-size: 18px;
  clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 89%, 0 0);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inner {
  width: 100%;
  padding: 30px;
  background-color: #020c23;
  clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 89%, 0 0);
}

.perfect-score a,
.perfect-score > div {
  background-color: #0e97f1 !important;
}

.perfectscore-text {
  font-size: 9px;
  margin-left: 10px;
  background-color: rgb(255, 255, 255);
  padding: 2px 10px;
  border-radius: 50px;
  color: #000c14;
}

/*********************************** MEDIA *****************************************/

@media (max-width: 910px) {
  :root {
    --row-height: 40px;
    --cell-spacing: 1px;
    --check-col-width: 30px;
  }

  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  .header-ctn {
    width: 100%;
    padding: 15px 10px;
  }

  .header-ctn h1 {
    font-size: 18px;
  }

  .blip {
    height: 15px;
    left: 30px;
    top: 30px;
  }

  .nav-ctn a,
  .navlink {
    font-size: 12px;
    margin-left: 10px;
  }

  .nav-ctn a:first-child {
    display: none;
  }

  .glow-on-hover {
    font-size: 12px !important;
    padding: 3px 10px;
    border-radius: 1px;
  }

  .list-ctn {
    padding: 0 1px 100px;
  }

  .rank-col {
    width: 25px;
    font-size: 12px;
  }

  .logo-col {
    padding-left: 6px;
  }

  .logo-col img {
    height: unset;
    max-width: 65px;
    max-height: 20px;
  }

  .check-col img {
    height: 10px;
  }

  .check-col:last-child {
    margin-right: 0;
  }

  .decimal-col span,
  .fee-questionmark,
  .check-col {
    font-size: 14px;
  }

  .decimal-col {
    font-size: 11px;
  }

  .list-label-ctn {
    margin-left: 1px;
  }

  .list-label-ctn:first-child {
    margin-left: -1px;
  }

  .list-header-ctn-inner-INSTANT {
    width: calc((var(--check-col-width) * 5) + 3px);
  }

  .list-header-ctn-inner-APPS {
    width: calc((var(--check-col-width) * 3) + 2px);
  }

  .list-label {
    transform: rotate(-90deg);
    margin-bottom: 15px;
    font-size: 12px;
    margin-left: calc(var(--check-col-width) / 2 - 16px);
  }

  .list-header-ctn {
    height: 180px;
  }

  .bottom-text-ctn p {
    padding: 0 10px;
    font-size: 14px;
  }

  .modal-border {
    width: 98%;
    max-width: unset;
    line-height: 1.8em;
    font-size: 14px;
  }

  .modal-inner {
    padding: 20px 10px;
  }

  .list-label-background {
    width: calc(var(--check-col-width));
    height: 180px;
    bottom: 1px;
    transform: skewX(0deg);
    margin-left: 0px;
  }

  .list-header-ctn-inner {
    width: calc((var(--check-col-width) * 7) + 6px);
  }

  .list-header-ctn h2 {
    flex-grow: 2;
    margin: 0 0 15px 20px;
    font-size: 16px;
  }

  .fee-col {
    font-size: 8px;
  }

  .details-ctn {
    margin-top: 20px;
    padding: 20px 10px;
    clip-path: none;
    box-sizing: border-box;
  }

  .detail-row h3 {
    font-size: 14px;
  }

  .logo-score-ctn {
    margin-bottom: 15px;
  }

  .detail-row-checkbox {
    height: 30px;
    width: 30px;
    /* margin-right: 10px; */
    font-size: 14px;
  }

  .detail-row-checkbox img {
    height: 10px;
  }

  .error-row {
    font-size: 14px;
    padding: 10px 20px 10px 40px;
  }

  .score-ctn {
    margin-top: 20px;
  }

  .scorebox {
    height: 40px;
    width: 40px;
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .perfectscore-text {
    display: none;
  }
}
