.barchart-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
}

.barchart-ctn-inner {
  width: 100%;
  background-color: #1a253b;
  padding: 0px 40px 20px 40px;
  box-sizing: border-box;
  max-width: 1200px;
  text-align: center;
}

.barchart-ctn-inner h2 {
  text-align: center;
  margin: 30px 0;
  font-size: 18px;
}

.barchart-button-ctn {
  max-width: 1200px;
  width: 100%;
  margin-bottom: 2px;
  display: flex;
}

.back-btn2 {
  padding: 10px 10px;
  margin-top: 2px;
  background-color: #1a253b;
  font-size: 12px;
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 2px;
  color: #fff;
  transition: all ease-in-out 100ms;
  cursor: pointer;
}

.back-btn3 {
  padding: 10px 40px;
  margin-top: 2px;
  background-color: #1a253b;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #fff;
  transition: all ease-in-out 100ms;
  cursor: pointer;
}

.back-btn2:hover,
.back-btn3:hover {
  background-color: #354564;
}

.doughnut-ctn {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.doughnut-ctn-inner {
  width: 100%;
  max-width: 500px;
}

.yes-row {
  width: 100%;
  margin-top: 40px;
  max-width: 800px;
}

.yes-row span {
  font-weight: 700;
}

.all-ctn-inner {
  width: 100%;
  background-color: #1a253b;
  padding: 0px 40px 20px 40px;
  box-sizing: border-box;
  max-width: 1200px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.barchart-compare-all-ctn {
  width: 20%;
  padding: 20px;
  box-sizing: border-box;
}

.two-bar-chart-ctn {
  display: flex;
  align-items: baseline;
  align-items: stretch;
}

.two-bar-chart-inner {
  width: 70%;
  height: 400px;
  padding-bottom: 30px;
}

.two-bar-chart-inner h4 {
  margin: 0;
}

.two-bar-chart-inner:first-child {
  width: 30%;
}

@media (max-width: 910px) {
  .barchart-button-ctn {
    flex-wrap: wrap;
  }
  .back-btn2 {
    padding: 10px 0px;
    margin-top: 2px;
    width: 100%;
    margin-right: 0px;
  }

  .back-btn3 {
    padding: 10px 0px;
    margin-top: 2px;
    width: 100%;
    margin-right: 0px;
  }

  .two-bar-chart-ctn {
    flex-direction: column;
  }

  .two-bar-chart-inner {
    width: 100%;
    height: 400px;
    padding-bottom: 30px;
  }

  .two-bar-chart-inner:first-child {
    width: 100%;
    margin-bottom: 30px;
  }
}
